<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.cms.title }}</strong>
              </CCol>
              <CCol sm="6" class="align-self-center">
                <div class="text-sm-right mt-3 mt-sm-0">
                  <CButton
                      color="primary"
                      v-on:click="createTableRow"
                      v-if="checkPermission('create-cms-page')"
                      style="margin-right: 10px"
                  >
                    {{ $lang.buttons.general.crud.create }}
                  </CButton>
                  <CButtonGroup class="file-export">
                    <CButton color="secondary" disabled>
                      {{ $lang.buttons.export.title }}
                    </CButton>
                    <CButton
                        color="danger"
                        v-c-tooltip="$lang.buttons.export.pdf"
                        v-on:click="exports(self, 'pdf', module, 'cms')"
                    >
                      <i class="fas fa-file-pdf"></i>
                    </CButton>
                    <VueJsonToCsv
                        class="btn btn-success"
                        v-c-tooltip="$lang.buttons.export.excel"
                        :json-data="json_data"
                        :labels="json_label"
                        :csv-title="json_title"
                    >
                      <i class="fas fa-file-excel"></i>
                    </VueJsonToCsv>
                  </CButtonGroup>
                </div>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <!--@loaded="exports(self,'Excel',module,'Rules')"-->

            <CAlert
                v-show="alertMessage"
                :color="messageColor"
                closeButton
                :show.sync="dismissCountDown"
                fade
            >
              {{ alertMessage }}
            </CAlert>
            <v-server-table
                :columns="columns"
                url="/cms/list"
                :options="options"
                ref="myTable"
                @loaded="exports(self, 'Excel', module, 'cms')"
            >
              <template #status="data">
                <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
              </template>
              <template #pageMaster="data">
                <span :title="data.row.pageMaster.pageTitle">{{
                    trimfunction(data.row.pageMaster.pageTitle, 50)
                  }}</span>
              </template>
              <template #title="data">
                <span :title="data.row.title">{{
                    trimfunction(data.row.title, 75)
                  }}</span>
              </template>
              <template #actions="data">
                <CButton
                    color="success"
                    v-c-tooltip="$lang.buttons.general.crud.edit"
                    v-on:click="editTableRow(data.row.id)"
                    v-if="checkPermission('edit-cms-page')"
                >
                  <i class="fas fa-pencil-alt"></i>
                </CButton>
                <CButton
                    v-show="deleteShow"
                    v-c-tooltip="$lang.buttons.general.crud.delete"
                    color="danger"
                    v-on:click="deletePopup(data.row.id)"
                    v-if="checkPermission('delete-cms-page')"
                >
                  <i class="fas fa-trash"></i>
                </CButton>
              </template>
            </v-server-table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {modules, cms} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "CmsItem",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  created() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    if (localStorage.getItem("notificationMessage") !== "") {
      self.dismissCountDown = 10;
      self.alertMessage = localStorage.getItem("notificationMessage");
      self.messageColor = localStorage.getItem("notificationType");
      localStorage.setItem("notificationMessage", "");
      localStorage.setItem("notificationType", "");
    }
  },

  data() {
    return {
      deleteShow: true,
      json_data: [],
      json_label: {},
      json_title: "",
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "",
      messageColor: "success",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      modules: [],
      activePage: 1,
      moduleMaster: modules,
      module: cms,
      columns: ["pageMaster", 'type', "title", "status", "actions"],
      data: [],
      options: {
        headings: {
          pageMaster: this.$lang.cms.table.page,
          type: this.$lang.cms.table.type,
          title: this.$lang.cms.table.title,
          status: this.$lang.cms.table.status,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["pageMaster", "title"],
        sortable: ["pageMaster", "title"],
        filterable: ["pageMaster", "title"],
      },
    };
  },
  mounted() {
    let self = this;
    self.adminId = localStorage.getItem("userId");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {
    editTableRow(item) {
      // this.$router.push({path: `/edit/${(item)}`})
      this.$router.push({name: "Edit Page", params: {id: item}});
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          // eslint-disable-next-line no-undef
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDown = 10;
          });
        }
      });
    },
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    setAlert(value) {
      this.showAlert = value;
    },
    createTableRow() {
      this.$router.push({name: "Add Page"});
    },

    rowClicked(item) {
      this.$router.push({path: `rules/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
